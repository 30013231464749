<template>
  <div class="common-usertags-add">
    <el-dialog
      :center="true"
      @close="tableVisible = false"
      :visible="tableVisible"
      :title="title"
      width="50%"
    >
      <div>
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
        >
          <el-form-item label="标签名称" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入标签名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              :rows="3"
              :minlength="1"
              :maxlength="1000"
              v-model="formData.remarks"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item label="标签类型" prop="type">
            <el-radio-group v-model="formData.type">
              <el-radio :label="1">平台 </el-radio>
              <el-radio :label="2">城市仓 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="tableVisible = false">返回</el-button>
        <el-button @click="determine" type="primary" :loading="bntloading">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  name: "common-usertags-add",
  props: {},
  data() {
    return {
      tableVisible: false,
      bntloading:false,
      title: "",
      formData: {
        name: "",
        id: "",
        type: 1,
        remarks: "",
      },
      rules: {
        name: [{ required: true, message: "请输入标签名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择标签", trigger: "change" }],
      },
    };
  },
  computed: {},
  methods: {
    hqinfo(e,item) {
      this.title = e;
      Object.assign(this.$data.formData, this.$options.data().formData);
      if(item){
        this.formData=JSON.parse(JSON.stringify(item));

      }
      this.tableVisible = true;
    },
    determine() {
        this.bntloading=true;
      this.$api.member.memberlabelstore(this.formData).then((res) => {
        this.$message.success(this.formData.id?'编辑成功':"添加成功");
        this.$emit("dialogVisibleChange",this.formData);
        this.tableVisible = false;
        this.bntloading=false;
      }).catch(err=>{
        this.bntloading=false;
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.common-usertags-add {
  .pagination-position {
    margin-top: 20px;
  }
}
</style>
  