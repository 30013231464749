var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common-usertags-add" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            center: true,
            visible: _vm.tableVisible,
            title: _vm.title,
            width: "50%",
          },
          on: {
            close: function ($event) {
              _vm.tableVisible = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.tableVisible = false
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.bntloading },
                      on: { click: _vm.determine },
                    },
                    [_vm._v("确定")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入标签名称" },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remarks" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          minlength: 1,
                          maxlength: 1000,
                          placeholder: "请输入备注",
                        },
                        model: {
                          value: _vm.formData.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "remarks", $$v)
                          },
                          expression: "formData.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签类型", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "type", $$v)
                            },
                            expression: "formData.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("平台 "),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("城市仓 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }