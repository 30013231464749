<template>
  <div class="usertags">
    <div class="title">
      <div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="add(false)" type="primary">新增</el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="from.name"
            placeholder="请输入标签名称"
            clearable
          ></el-input>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" align="center" label="ID"> </el-table-column>
        <el-table-column prop="name" align="center" label="标签名称">
        </el-table-column>
        <el-table-column prop="remarks" align="center" label="备注">
        </el-table-column>
        <el-table-column prop="member_count" align="center" label="绑定用户">
        </el-table-column>
        <el-table-column prop="operate_name" align="center" label="添加人">
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="添加时间">
        </el-table-column>
        <el-table-column prop="type" align="center" label="标签类型">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? "平台" : "城市仓" }}
          </template>
        </el-table-column>
        <el-table-column prop="order_no" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="relation(scope.row)"
              >关联用户</el-button
            >
            <el-button type="text" @click="handleEdit(scope.row)"
              >清空</el-button
            >
            <el-button type="text" @click="add(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <commonusertagadd
      ref="commonusertagadd"
      @dialogVisibleChange="dialogVisibleChange"
    ></commonusertagadd>
    <commonusertagrelation
      ref="commonusertagrelation"
      @dialogVisibleChange="dialogVisibleChange"
    ></commonusertagrelation>
  </div>
</template>
  <script>
import commonusertagadd from "./common-usertags/add.vue";
import commonusertagrelation from "./common-usertags/relation.vue";
export default {
  name: "usertags",
  components: { commonusertagadd, commonusertagrelation },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        name: "",
        page: 1,
        pageSize: 50,
      },
      from1: {
        name: "",
        page: 1,
        pageSize: 50,
      },
      tableData: [],
      currentPage: 1,
      total: 0,
    };
  },
  created() {
    this.hqlist();
  },
  methods: {
    handleEdit(row) {
      this.$confirm("确定要清空吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$api.member
            .memberlabelclear({
              id: row.id,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "已清空",
              });
              this.hqlist();
            });
        })
        .catch(() => {});
    },
    dialogVisibleChange(e) {
      if (e&&!e.id) {
        this.from.page = 1;
        this.from1 = JSON.parse(JSON.stringify(this.from));
      }
      this.hqlist();
    },
    relation(e) {
      this.$refs.commonusertagrelation.addData(e);
    },
    add(e) {
      this.$refs.commonusertagadd.hqinfo(
        e ? "编辑标签" : "新增标签",
        e ? e : ""
      );
    },
    hqlist() {
      this.loading = true;
      this.$api.member
        .memberlist(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    addclick() {
      console.log("新增");
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      Object.assign(this.$data.from1, this.$options.data().from1);
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
  <style lang="scss">
.usertags {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .inputs {
        // width: 207px;
        margin-right: 8px;
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
  